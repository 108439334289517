/**
 * Feedback API
 * Fenix NestJS feedback service
 *
 * The version of the OpenAPI document: 4.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Приложение
 */
export type ApplicationEnum = 'Archive' | 'Auto' | 'CrmCollection' | 'Mortgage' | 'Verification';

export const ApplicationEnum = {
  Archive: 'Archive' as ApplicationEnum,
  Auto: 'Auto' as ApplicationEnum,
  CrmCollection: 'CrmCollection' as ApplicationEnum,
  Mortgage: 'Mortgage' as ApplicationEnum,
  Verification: 'Verification' as ApplicationEnum,
};
