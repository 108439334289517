import { withCabinetTitle } from '@lib-config';
import { ExtendedRoute, IPOTEKA_VIDEO_INSTRUCTION_URL, NEW_LOGO_SRC, RouteBreadcrumbData } from '@lib-utils';
import { NoPageComponent } from '@lib-widgets/core';
import {
  authorizationGuardFn,
  getAuthorizationRoutes,
  permissionGuardFn,
  RoutePermissionData,
} from '@lib-mortgage/features/authorization';
import { ApplicationModules, ApplicationModulesMetaMap } from '@lib-mortgage/utils';
import { DefaultComponent, DefaultPageData } from '@lib-mortgage/widgets/default';
import { environment } from '../environments/environment';
import { lawyerTitleResolver } from './views/lawyer/lawyer-title.resolver';

export const appRoutes: ExtendedRoute<RouteBreadcrumbData & RoutePermissionData & DefaultPageData>[] = [
  {
    path: '',
    title: 'Ипотечное кредитование',
    children: [
      {
        path: '',
        pathMatch: 'full',
        canActivate: [authorizationGuardFn],
        component: DefaultComponent,
        data: {
          moduleMeta: ApplicationModulesMetaMap,
          videoInstructionUrl: IPOTEKA_VIDEO_INSTRUCTION_URL,
          logo: NEW_LOGO_SRC,
          hasTranslation: false,
        },
      },
      {
        title: 'Админка',
        path: ApplicationModulesMetaMap[ApplicationModules.Admin].link,
        canActivate: [authorizationGuardFn, permissionGuardFn],
        canActivateChild: [authorizationGuardFn],
        loadChildren: () => import('./views/admin').then((m) => m.AdminModule),
        data: {
          ...withCabinetTitle('Админка'),
          requiredPermission: ApplicationModulesMetaMap[ApplicationModules.Admin].permissionOperation,
        },
      },
      {
        title: 'Кабинет заявок',
        path: ApplicationModulesMetaMap[ApplicationModules.Orders].link,
        canActivate: [authorizationGuardFn, permissionGuardFn],
        canActivateChild: [authorizationGuardFn],
        loadChildren: () => import('./views/orders').then((m) => m.OrdersModule),
        data: {
          ...withCabinetTitle('Кабинет заявок'),
          requiredPermission: ApplicationModulesMetaMap[ApplicationModules.Orders].permissionOperation,
        },
      },
      {
        title: 'Кабинет авторизатора',
        path: ApplicationModulesMetaMap[ApplicationModules.Authorizer].link,
        canActivate: [authorizationGuardFn, permissionGuardFn],
        canActivateChild: [authorizationGuardFn],
        loadChildren: () => import('./views/authorizer').then((m) => m.AuthorizerModule),
        data: {
          ...withCabinetTitle('Кабинет авторизатора'),
          requiredPermission: ApplicationModulesMetaMap[ApplicationModules.Authorizer].permissionOperation,
        },
      },
      {
        title: 'Кабинет андеррайтера',
        path: ApplicationModulesMetaMap[ApplicationModules.Underwriter].link,
        canActivate: [authorizationGuardFn, permissionGuardFn],
        canActivateChild: [authorizationGuardFn],
        loadChildren: () => import('./views/underwriter').then((m) => m.UnderwriterModule),
        data: {
          ...withCabinetTitle('Кабинет андеррайтера'),
          requiredPermission: ApplicationModulesMetaMap[ApplicationModules.Underwriter].permissionOperation,
        },
      },
      {
        title: lawyerTitleResolver,
        path: ApplicationModulesMetaMap[ApplicationModules.Lawyer].link,
        canActivate: [authorizationGuardFn, permissionGuardFn],
        canActivateChild: [authorizationGuardFn],
        data: {
          requiredPermission: ApplicationModulesMetaMap[ApplicationModules.Lawyer].permissionOperation,
        },
        loadChildren: () => import('./views/lawyer').then((m) => m.LawyerModule),
      },
      {
        title: 'Кабинет верификации сделки и выдачи',
        path: ApplicationModulesMetaMap[ApplicationModules.DealDocumentsVerificator].link,
        canActivate: [authorizationGuardFn, permissionGuardFn],
        canActivateChild: [authorizationGuardFn],
        loadChildren: () => import('./views/deal-documents-verificator').then((m) => m.DealDocumentsVerificatorModule),
        data: {
          ...withCabinetTitle('Кабинет верификации сделки и выдачи'),
          requiredPermission:
            ApplicationModulesMetaMap[ApplicationModules.DealDocumentsVerificator].permissionOperation,
        },
      },
    ],
  },
  {
    path: 'auth',
    children: getAuthorizationRoutes(environment.frontVersion, 'Ипотека', true),
  },
  {
    path: 'unsupported-browser',
    title: 'Ваш браузер не поддерживается',
    loadComponent: () => import('@lib-widgets/unsupported-browser').then((m) => m.UnsupportedBrowserComponent),
  },
  {
    path: '**',
    title: 'Страница не найдена',
    component: NoPageComponent,
  },
];
