/**
 * Feedback API
 * Fenix NestJS feedback service
 *
 * The version of the OpenAPI document: 4.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type IssuePriorityEnum = 'Blocker' | 'Critical' | 'Normal' | 'Minor';

export const IssuePriorityEnum = {
  Blocker: 'Blocker' as IssuePriorityEnum,
  Critical: 'Critical' as IssuePriorityEnum,
  Normal: 'Normal' as IssuePriorityEnum,
  Minor: 'Minor' as IssuePriorityEnum,
};
