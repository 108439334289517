/* tslint:disable:no-unused-variable member-ordering */
import {
  HttpClient,
  HttpContext,
  HttpEvent,
  HttpHeaders,
  HttpParameterCodec,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Inject, Injectable, isDevMode, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { FeedbackConfiguration } from '../configuration';
import { CustomHttpParameterCodec } from '../encoder';
// @ts-ignore
import { FeedbackRequestDto } from '../model/feedbackRequestDto';
// @ts-ignore
import { FeedbackResponseDto } from '../model/feedbackResponseDto';
// @ts-ignore
import { UploadFeedbackFilesResponseDto } from '../model/uploadFeedbackFilesResponseDto';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';

export interface FeedbackControllerSendFeedbackRequestParams {
  feedbackRequestDto: FeedbackRequestDto;
}

export interface FeedbackControllerUploadFilesRequestParams {
  issueKey: string;
  files: Array<Blob>;
}

const enum AuthorizationType {
  Bearer = 'Bearer',
}

const enum AuthorizationHeader {
  Authorization = 'Authorization',
}

const AcceptHeader = 'Accept';

@Injectable()
export class FeedbackApiService {
  protected basePath = 'http://localhost:3000';
  public defaultHeaders = new HttpHeaders();
  public configuration = new FeedbackConfiguration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string | string[],
    @Optional() configuration: FeedbackConfiguration,
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (Array.isArray(basePath) && basePath.length > 0) {
        basePath = basePath[0];
      }

      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  checkAuthentication(localVarHeaders: any, authType: AuthorizationType, paramName: AuthorizationHeader) {
    // authentication
    const localVarCredential = this.configuration.lookupCredential(authType as string); // name
    if (localVarCredential) {
      return localVarHeaders.set(paramName as string, localVarCredential); // keyParamName
    }

    return localVarHeaders;
  }

  getAcceptType(localVarHttpHeaderAcceptSelected?: string): 'text' | 'json' | 'blob' {
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        return 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        return 'json';
      } else {
        return 'blob';
      }
    }

    return 'json';
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  // @ts-ignore
  private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
    httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    return httpParams;
  }

  private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      httpParams = httpParams.append(key!, JSON.stringify(value));
    } else {
      httpParams = httpParams.append(key!, value);
    }

    return httpParams;
  }

  /**
   * Отправить фидбек.
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public feedbackControllerSendFeedback(
    requestParameters: FeedbackControllerSendFeedbackRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext },
  ): Observable<FeedbackResponseDto>;
  public feedbackControllerSendFeedback(
    requestParameters: FeedbackControllerSendFeedbackRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext },
  ): Observable<HttpResponse<FeedbackResponseDto>>;
  public feedbackControllerSendFeedback(
    requestParameters: FeedbackControllerSendFeedbackRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext },
  ): Observable<HttpEvent<FeedbackResponseDto>>;
  public feedbackControllerSendFeedback(
    requestParameters: FeedbackControllerSendFeedbackRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext },
  ): Observable<any> {
    const feedbackRequestDto = requestParameters.feedbackRequestDto;

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let localVarPath = `/api/feedback`;
    return this.httpClient.request<FeedbackResponseDto>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: feedbackRequestDto,
      responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Загрузка файлов к отправленному фидбеку
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public feedbackControllerUploadFiles(
    requestParameters: FeedbackControllerUploadFilesRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext },
  ): Observable<UploadFeedbackFilesResponseDto>;
  public feedbackControllerUploadFiles(
    requestParameters: FeedbackControllerUploadFilesRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext },
  ): Observable<HttpResponse<UploadFeedbackFilesResponseDto>>;
  public feedbackControllerUploadFiles(
    requestParameters: FeedbackControllerUploadFilesRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext },
  ): Observable<HttpEvent<UploadFeedbackFilesResponseDto>>;
  public feedbackControllerUploadFiles(
    requestParameters: FeedbackControllerUploadFilesRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext },
  ): Observable<any> {
    const issueKey = requestParameters.issueKey;

    const files = requestParameters.files;

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    // to determine the Content-Type header
    const consumes: string[] = ['multipart/form-data'];

    const canConsumeForm = this.canConsumeForm(consumes);

    let localVarFormParams: { append(param: string, value: any): any };
    let localVarUseForm = false;
    let localVarConvertFormParamsToString = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
    localVarUseForm = canConsumeForm;
    if (localVarUseForm) {
      localVarFormParams = new FormData();
    } else {
      localVarFormParams = new HttpParams({ encoder: this.encoder });
    }

    if (files) {
      if (localVarUseForm) {
        files.forEach((element) => {
          localVarFormParams = (localVarFormParams.append('files', <any>element) as any) || localVarFormParams;
        });
      } else {
        localVarFormParams =
          (localVarFormParams.append('files', [...files].join(COLLECTION_FORMATS['csv'])) as any) || localVarFormParams;
      }
    }

    let localVarPath = `/api/feedback/upload-files/${this.configuration.encodeParam({
      name: 'issueKey',
      value: issueKey,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}`;
    return this.httpClient.request<UploadFeedbackFilesResponseDto>(
      'post',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
        responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }
}
