/**
 * Feedback API
 * Fenix NestJS feedback service
 *
 * The version of the OpenAPI document: 4.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Тип обращения ``` ReportIssue - Сообщение об ошибке ConsultationRequest - Запрос на консультацию CreditIssuingIssue - Проблема с выдачей IncomeAdjustment - Корректировка дохода IssuedStatus - Статус выдан VerificationStuckIssue - Зависание на проверке внутренних источников/верификации ChangeInRate - Изменение ставки ```
 */
export type FeedbackTypeEnum =
  | 'ReportIssue'
  | 'ConsultationRequest'
  | 'CreditIssuingIssue'
  | 'IncomeAdjustment'
  | 'IssuedStatus'
  | 'VerificationStuckIssue'
  | 'ChangeInRate';

export const FeedbackTypeEnum = {
  ReportIssue: 'ReportIssue' as FeedbackTypeEnum,
  ConsultationRequest: 'ConsultationRequest' as FeedbackTypeEnum,
  CreditIssuingIssue: 'CreditIssuingIssue' as FeedbackTypeEnum,
  IncomeAdjustment: 'IncomeAdjustment' as FeedbackTypeEnum,
  IssuedStatus: 'IssuedStatus' as FeedbackTypeEnum,
  VerificationStuckIssue: 'VerificationStuckIssue' as FeedbackTypeEnum,
  ChangeInRate: 'ChangeInRate' as FeedbackTypeEnum,
};
