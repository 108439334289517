/**
 * Feedback API
 * Fenix NestJS feedback service
 *
 * The version of the OpenAPI document: 4.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ApplicationEnum } from './applicationEnum';
import { FeedbackRequestDtoUserEntity } from './feedbackRequestDtoUserEntity';
import { FeedbackTypeEnum } from './feedbackTypeEnum';
import { IssueDeadlineEnum } from './issueDeadlineEnum';
import { IssuePriorityEnum } from './issuePriorityEnum';
import { RoleInfoDto } from './roleInfoDto';

export interface FeedbackRequestDto {
  type: FeedbackTypeEnum;
  priority?: IssuePriorityEnum;
  issueDeadline?: IssueDeadlineEnum;
  userEntity: FeedbackRequestDtoUserEntity;
  /**
   * Роли пользователя, если мультиролль из UserState.userRoles
   */
  userRoles: Array<RoleInfoDto>;
  application: ApplicationEnum;
  /**
   * Путь в адресной строке
   */
  path: string;
  /**
   * Номер заявки
   */
  entityId?: string;
  /**
   * Окружение
   */
  environment: string;
  /**
   * Версия приложения
   */
  applicationVersion: string;
  /**
   * Описание ошибки
   */
  description: string;
}
