import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { ConfigService } from '@lib-config';
import { AuthorizationStorageService } from '@lib-mortgage/features/authorization';
import { ApplicationModules, ApplicationModulesMetaMap } from '@lib-mortgage/utils';
import { getModuleMeta } from '@lib-mortgage/widgets/default';

export const lawyerTitleResolver: ResolveFn<string> = () => {
  const { name } = getModuleMeta(ApplicationModulesMetaMap[ApplicationModules.Lawyer], {
    hasRoles: inject(AuthorizationStorageService).hasRoles,
    configService: inject(ConfigService),
  });

  return name;
};
