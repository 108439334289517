/**
 * Feedback API
 * Fenix NestJS feedback service
 *
 * The version of the OpenAPI document: 4.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Когда выдача (для проблем с выдачей)
 */
export type IssueDeadlineEnum = 'Today' | 'Tomorrow' | 'NextFewDays' | 'InFuture';

export const IssueDeadlineEnum = {
  Today: 'Today' as IssueDeadlineEnum,
  Tomorrow: 'Tomorrow' as IssueDeadlineEnum,
  NextFewDays: 'NextFewDays' as IssueDeadlineEnum,
  InFuture: 'InFuture' as IssueDeadlineEnum,
};
